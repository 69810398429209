import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LockedState } from '../../service/lockout.service';
import { ExamSectionBreakResponseDto } from '../../model/exam/exam-section-break-response-dto';
import { KeyValueRepositoryService } from '../repositories/key-value-repository.service';

@Injectable({
    providedIn: 'root'
})
export class KeyValueDataService {

    constructor(private keyValueRepository: KeyValueRepositoryService) { }

    public setLearnerName(learnerName: string): Observable<void> {
        return this.keyValueRepository.setLearnerName(learnerName);
	}

	public getCurrentBlockIndex(): Observable<number> {
		return this.keyValueRepository.getCurrentBlock();
	}

	public setCurrentBlock(currentBlock: number): Observable<void> {
		return this.keyValueRepository.setCurrentBlock(currentBlock);
	}

    public getLearnerName(): Observable<string> {
        return this.keyValueRepository.getLearnerName();
    }

    public setSubjectName(subjectName: string): Observable<void> {
        return this.keyValueRepository.setSubjectName(subjectName);
    }

    public getSubjectName(): Observable<string> {
        return this.keyValueRepository.getSubjectName();
    }

    public setScheduledFinish(scheduledFinish: Date): Observable<void> {
        return this.keyValueRepository.setScheduledFinish(scheduledFinish);
    }

    public getScheduledFinish(): Observable<Date> {
        return this.keyValueRepository.getScheduledFinish();
	}

	public getCurrentSectionNumber(): Observable<number> {
		return this.keyValueRepository.getCurrentSectionNumber();
    }

	public getInBreak(): Observable<boolean> {
		return this.keyValueRepository.getInBreak();
	}

	public setInBreak(value: boolean): Observable<void> {
		return this.keyValueRepository.setInBreak(value);
	}

	public getBreakTime(): Observable<ExamSectionBreakResponseDto> {
		return this.keyValueRepository.getBreakTimes();
	}

	public setBreakTimes(value: ExamSectionBreakResponseDto): Observable<void> {
		return this.keyValueRepository.setBreakTimes(value);
    }

    public setExamType(examType: string): Observable<void> {
        return this.keyValueRepository.setExamType(examType);
    }

    public getExamType(): Observable<string> {
        return this.keyValueRepository.getExamType();
    }

	public setCurrentSectionNumber(value: number): Observable<void> {
		return this.keyValueRepository.setCurrentSectionNumber(value);
	}

    public setEndTime(endTime: Date): Observable<void> {
        return this.keyValueRepository.setEndTime(endTime);
    }

    public getEndTime(): Observable<Date> {
        return this.keyValueRepository.getEndTime();
    }

    public setLockedState(lockedState: LockedState): Observable<void> {
        return this.keyValueRepository.setLockedState(lockedState);
    }

    public getLockedState(): Observable<LockedState> {
        return this.keyValueRepository.getLockedState();
    }

    public setExamCodeChecksum(examCode: string): Observable<void> {
        return this.keyValueRepository.setExamCodeChecksum(examCode);
    }

    public getExamCodeChecksum(): Observable<string> {
        return this.keyValueRepository.getExamCodeChecksum();
    }

    public setMockFlag(isMock: boolean): Observable<void> {
        return this.keyValueRepository.setMockFlag(isMock);
    }

    public getMockFlag(): Observable<boolean> {
        return this.keyValueRepository.getMockFlag();
    }

    public setCurrentQuestionNumber(questionNumber: number): Observable<void> {
        return this.keyValueRepository.setCurrentQuestionNumber(questionNumber);
    }

    public getCurrentQuestionNumber(): Observable<number> {
        return this.keyValueRepository.getCurrentQuestionNumber();
    }

    public setIntroductionGuidance(introductionGuidance: string): Observable<void> {
        return this.keyValueRepository.setIntroductionGuidance(introductionGuidance);
    }

    public getIntroductionGuidance(): Observable<string> {
        return this.keyValueRepository.getIntroductionGuidance();
    }

    public getIsPaused(): Observable<boolean> {
        return this.keyValueRepository.getIsPaused();
    }

    public setIsPaused(isPaused: boolean): Observable<void> {
        return this.keyValueRepository.setIsPaused(isPaused);
	}

	public getPaperCode(): Observable<string> {
		return this.keyValueRepository.getPaperCode();
	}

	public setPaperCode(paperCode: string): Observable<void> {
		return this.keyValueRepository.setPaperCode(paperCode);
	}

	public getCanNavigateAway(): Observable<boolean> {
		return this.keyValueRepository.getCanNavigateAway();
	}
	public setCanNavigateAway(canNavigateAway: boolean): Observable<void> {
		return this.keyValueRepository.setCanNavigateAway(canNavigateAway);
	}
}
